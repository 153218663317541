<template>
  <pcis-steps ref="steps" :enableButtons="!loading">
    <!-- <div
      class="loading"
      v-loading="true"
      :element-loading-text="$t('common.loading.process')"
    /> -->
    <el-card v-loading="loading" element-loading-background="#ffffff" :element-loading-text="$t('common.loading.default')" class="pay-card">
      <p>{{$t(`personal.payment.qr.instruction.${method}`)}}</p>
      <el-divider />
      <div class="pay-frame" id="pay" />
      <el-divider />
    </el-card>
    <template #buttons>
      <!-- <div class="steps-button"> -->
        <el-row :gutter="10" class="steps-button">
          <el-col :span="12">
            <el-button
              type="default"
              round
              @click="onPrevClick"
            >
              {{ $t("common.button.back") }}
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button
              type="primary"
              round
              @click="completePayment"
            >
              {{ $t("common.button.completePayment") }}
            </el-button>
          </el-col>
        </el-row>
      <!-- </div> -->
      <!-- <div class="steps-button"> -->
      <!-- </div> -->
    </template>
  </pcis-steps>
</template>

<style scoped>
@media screen and (max-width: 709px) {
  .loading {
    min-height: calc(100vh - 214px);
    width: 100%;
  }
}

@media screen and (min-width: 710px) {
  .loading {
    min-height: calc(100vh - 197px);
    width: 100%;
  }
}

.steps-button {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 650px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.steps-button >>> .el-button {
  width: 100%;
  max-width: 300px;
}

@media screen and (max-width: 767px) {
  .steps-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 0px 20px;
  }
}

.pay-frame {
  /* text-align: center;
  margin: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.pay-frame >>> iframe {
  width: 305px;
  height: 305px;
  border: none;
  transform: scale(0.95);
}
.pay-card {
  min-height: 250px;
}
</style>

<script>
import BaseStep from "@/views/personal/steps/BaseStep.vue";
// import store from "../../../store";
import { checkIfBoCPayment, handlePayment, handleVoidPayment } from "../../../libs/common";
// import i18n from "../../../language/index.js";
import * as message from "@/plugins/message";
import * as loading from "@/plugins/loading";
import payment from "../../../http/apis/personal/payment";

export default {
  extends: BaseStep,
  name: "PersonalPaymentProcess",
  data() {
    return {
      loading: true
    }
  },
  methods: {
    onPrevClick() {
      this.$refs.steps.goPrev();
    },
    onNextClick() {
      this.$refs.steps.goNext();
    },
    completePayment() {
      let personal = this.$store.state.personal;
      let loadingInstance = loading.create("process");
      payment.postConfirmPayment(personal.jwt, personal.form.payment.refNum, personal.form.id)
        .then((res) => {
          if (res.status == 200) {
            this.$store.commit("clearPersonalPaymentRefNum");
            if (res.data.status == 4) {
              this.onNextClick();
              return;
            }
            if (res.data.status == 5) {
              handleVoidPayment();
              return; 
            }
            this.onPrevClick();
          }
        }).catch((err) => {
          console.log(err)
          message.WarningAlert(this.$i18n.t("exceptions.incompletePayment"));
          // handlePayment(personal.form.id)
          // handlePersonalException(err)
        }).finally(() => {
          loadingInstance.close();
        });
      // let personal = this.$store.state.personal;
      // let loadingInstance = loading.create("process");
      // let payResult = await handlePayment(personal.form.id, false);
      // if (payResult.status == 4) {
      //   loadingInstance.close()
      //   this.$refs.steps.goNext();
      //   return;
      // }
      // loadingInstance.close();
      // message.WarningAlert(this.$i18n.t("exceptions.incompletePayment"));
    },
    async initFrame() {
      let personal = this.$store.state.personal;
      let params = this.$router.currentRoute.value.params;
      let url = null;
      let isQRPay = true;
      if (Object.prototype.hasOwnProperty.call(params, "url")) {
        url = params.url
      } else {
        let res = await handlePayment(personal.form.id, false);
        isQRPay = checkIfBoCPayment(res).isQR;
        url = res.redirectUrl;
      }
      if (!url || !isQRPay) {
        this.onPrevClick();
        return;
      }
      let iframe = document.createElement("iframe");
      iframe.setAttribute("src", url);
      iframe.setAttribute("sandbox", "allow-same-origin allow-scripts allow-top-navigation");
      iframe.onload = function(e) {
        console.log(e, e.currentTarget)
      }
      console.log(this.$refs);
      let payDiv = document.getElementById("pay");
      if (payDiv) {
        if (!payDiv.querySelector("iframe")) {
          payDiv.appendChild(iframe)
        }
      }
      this.loading = false;
      // return iframe;
    }
  },
  computed: {
    method() {
      return this.$store.state.personal.form.payment.method;
    }
  },
  mounted() {
    let vm = this;
    let interval = setInterval(()=> {
      if (document.getElementById("pay")) {
        vm.initFrame();
        clearInterval(interval);
      }
    }, 1000);
  }
};
</script>
